import * as React from 'react'
import { GetStaticProps } from 'next'
import { NotFound } from '../views/NotFound'
import { client } from '@/lib/client'
import { Sentry } from '@/lib/sentry'

const NotFoundPage = () => <NotFound />

export const getStaticProps: GetStaticProps = async (ctx) => {
  try {
    const siteSettings = await client.fetchSiteSettings()
    return { props: { siteSettings }, revalidate: 10 }
  } catch (e) {
    Sentry.captureException(e, 'page_fetch_error', { ctx })
    return { props: {}, revalidate: 1 }
  }
}

export default NotFoundPage
