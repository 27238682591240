import * as React from 'react'
import styled from '@xstyled/styled-components'
import { Text } from '@/components/Text'
import Link from 'next/link'

const NotFoundWrapper = styled.div`
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  background-color: white;

  img {
    width: 80px;
    margin-bottom: 2;
  }
`

export const NotFound: React.FC = () => {
  return (
    <NotFoundWrapper>
      <img src="/drawings/skull.svg" />
      <Text as="h1" fontFamily="sans" fontSize={3}>
        Sorry, this page was not found
      </Text>
      <Text mt={4} as="p">
        <Link href="/">Go home</Link>
      </Text>
    </NotFoundWrapper>
  )
}
